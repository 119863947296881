// getMessageDataWithVariable.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getMessageData from '../selector/getMessageData.js';
import getMeData from '../selector/getMeData.js';

export const VariableName = {
  USERNAME: 'username',
};

/**
 * Select message data with variable by message id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @param {string} messageDataKey - message data key
 * @param {string} valueString - value string, use space to concat each key value pair. example: "username:jacky fieldB:fieldBValue"
 * @return {string} Been transformed with variables message data.
 */
const getMessageDataWithVariable = createCachedSelector(
  (state, messageId, messageDataKey) =>
    getMessageData(state, messageId, messageDataKey),
  (state, messageId) => getMessageData(state, messageId, 'senderId'),
  state => getMeData(state, 'id'),
  (state, messageId, messageDataKey, valueString) => valueString,
  (messageValue = '', messageSenderId, meId, valueString = '') => {
    let result = messageValue;
    const isMessageSentFromMe = meId != null && messageSenderId === meId;
    valueString
      .split(' ')
      .filter(item => item)
      .forEach(item => {
        const [key, originValue] = item.split(':');
        const value = ['undefined', 'null', ''].includes(originValue)
          ? undefined
          : originValue;
        switch (key) {
          case VariableName.USERNAME: {
            if (!isMessageSentFromMe) {
              result = result.replace(
                new RegExp(`{${key}}`, 'ig'),
                value || 'Babe'
              );
            }
            break;
          }
          default: {
            result = result.replace(new RegExp(`{${key}}`, 'ig'), value || '');
          }
        }
      });
    return result;
  }
)(
  (state, messageId, messageDataKey, valueString) =>
    `${messageId}:${messageDataKey}:${valueString}`
);

export default getMessageDataWithVariable;
