import { connect } from 'react-redux';
import MessageThumbnail from '../component/MessageThumbnail.jsx';

import getUserData from '../selector/getUserData.js';
import getMessageData from '../selector/getMessageData.js';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import getMeData from '../selector/getMeData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { PREVIEW_PRESET } from '../RemoteConfigKeys.js';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const senderId = getMessageData(state, id, 'senderId');
  const meUsername = getMeData(state, 'username');
  const hasVerified = getRemoteConfigData(state, PREVIEW_PRESET) === 'clear';

  return {
    username: getUserData(state, senderId, 'username'),
    originalCaptionText: getMessageDataWithVariable(
      state,
      id,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    thumbnail: getMessageThumbnail(
      state,
      id,
      '',
      '256x256',
      'jpg',
      hasVerified ? '' : 'blurred'
    ),
  };
};
export default connect(mapStateToProps)(MessageThumbnail);
