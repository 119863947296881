// MessageThumbnail.jsx
import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DecryptionWrapper from '../container/DecryptionWrapper.js';

import { MediaAssetFormat } from '../resource/getMediaAsset.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import { NULL_FUNCTION } from '../resource/defaults.js';

const THUMBNAIL_LOAD_COMPLETE_TIMEOUT_IN_MSEC = 10000; // TODO: remote config

export const MessageThumbnail = ({
  username = '',
  originalCaptionText = '',
  thumbnail = '',
  objectFit = 'contain',
  size = 256,
  onLoad = NULL_FUNCTION,
}) => {
  const altText = username ? `${username} : ${originalCaptionText}` : '';

  const [avifSrcSet, webpSrcSet, jpgSrcSet] = useMemo(
    () =>
      [MediaAssetFormat.AVIF, MediaAssetFormat.WEBP, MediaAssetFormat.JPG].map(
        format =>
          getPublicSrcSet({ href: thumbnail, size: size || 256, format })
      ),
    [thumbnail, size]
  );

  const imageRef = useRef(null);
  const imageLoadedRef = useRef({ isLoaded: false });

  useEffect(() => {
    imageLoadedRef.current.isLoaded = false;

    // IMG.complete is supposed to be true even if there is an error,
    // but somehow it doesn't work as expected
    // if it's SSR and on mobile devices (neither iOS nor Android).
    // That's why we add a timeout mechanism in case the video couldn't play.
    const timer = setTimeout(() => {
      if (!imageLoadedRef.current.isLoaded) {
        imageLoadedRef.current.isLoaded = true;
        onLoad();
      }
    }, THUMBNAIL_LOAD_COMPLETE_TIMEOUT_IN_MSEC);
    return () => {
      clearTimeout(timer);
    };
  }, [thumbnail, onLoad]);

  useEffect(() => {
    // 1. The `onload` event doesn't emit as expected on SSR, that's why we add this hook as a fallback.
    // 2. Check for every render.
    if (imageRef?.current?.complete && !imageLoadedRef.current.isLoaded) {
      imageLoadedRef.current.isLoaded = true;
      onLoad();
    }
  });

  return (
    <picture
      onLoad={() => {
        if (!imageLoadedRef.current.isLoaded) {
          imageLoadedRef.current.isLoaded = true;
          onLoad();
        }
      }}
    >
      <DecryptionWrapper resourceUrl={jpgSrcSet.src}>
        <source {...avifSrcSet} type="image/avif" />
        <source {...webpSrcSet} type="image/webp" />
        <Image
          {...jpgSrcSet}
          alt={altText}
          objectFit={objectFit}
          ref={imageRef}
        />
      </DecryptionWrapper>
    </picture>
  );
};

MessageThumbnail.propTypes = {
  username: PropTypes.string,
  originalCaptionText: PropTypes.string,
  thumbnail: PropTypes.string,
  objectFit: PropTypes.string,
  size: PropTypes.number,
  onLoad: PropTypes.func,
};

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${({ objectFit }) => objectFit};
`;

export default MessageThumbnail;
